import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { Observable } from 'rxjs';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { InquirySearch } from './inquiry';
import { BInteraction } from '../interaction/interaction';
import { SearchItemModel } from './search.model';
import { Operator } from 'app/common/common/enums/search.enum';
import { Helpers } from '@mi-tool/utils/helpers';
import { ExportModel } from './export.model';

@Injectable({ providedIn: 'root' })
export class InquiriesService {
  private readonly URL: string;
  private readonly URL_SHARE: string;
  private readonly URL_SEARCH: string;
  private readonly URL_SAVED_SEARCH: string;
  private readonly URL_FILTER: string;
  private readonly URL_EXPORT: string;

  constructor(
    resolver: UrlResolverService,
    private http: HttpClient,
    private helperService: Helpers
  ) {
    this.URL = resolver.misApiUrlForPath('/inquiries/');
    this.URL_FILTER = resolver.misApiUrlForPath('/inquiries/filter');
    this.URL_SEARCH = resolver.misApiUrlForPath('/inquiries/search');
    this.URL_SAVED_SEARCH = resolver.misApiUrlForPath('/inquiries/saved_search');
    this.URL_SHARE = resolver.misApiUrlForPath('/inquiries/share');
    this.URL_EXPORT = resolver.misApiUrlForPath('/inquiries/export');
  }

  createQuestionFromFU(historyId: number): Observable<void> {
    return this.http.post<void>(this.URL, {
      historyId,
    });
  }

  copyAnswerFrom(inquiryId: number, isDraft: boolean, copyFromInquiryId: number): Observable<void> {
    return this.http.put<void>(this.URL, {
      inquiryId: inquiryId,
      isDraft: isDraft,
      copyFromInquiryId: copyFromInquiryId,
    });
  }

  getSingleSearch(id: number): Observable<InquirySearch> {
    return this.http.get<any>(this.URL_SAVED_SEARCH + '/' + id).pipe(
      map((resp) => {
        return InquirySearch.fromRest(resp);
      })
    );
  }

  getSearches(): Observable<InquirySearch[]> {
    return this.http
      .get<any>(this.URL_SEARCH, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => InquirySearch.fromRestArray(resp)));
  }

  search(
    inquiryFilter: InquirySearch,
    params?: { pageSize?: number; page?: number; orderBy?: string }
  ): Observable<any> {
    const queryParams = new Ajax.QueryParams()
      .add('page_size', params.pageSize || 10)
      .add('order_by', params.orderBy || '-created_ts')
      .add('page', params.page || 1);
    const body = this.getSearchBody(inquiryFilter);

    return this.http
      .post<any>(
        this.URL_FILTER + queryParams,
        { filters: body },
        {
          headers: {
            'X-NO-LOADING': 'true',
          },
        }
      )
      .pipe(
        map((resp) => {
          resp.result.map((i) => {
            i.landingInteraction = BInteraction.fromRest(i._object);
            return i;
          });
          return resp;
        })
      );
  }

  private getSearchBody(inquiryFilter: InquirySearch | any): SearchItemModel[] {
    let result: SearchItemModel[] = [];
    let data = inquiryFilter.advancedSearch || inquiryFilter.advanced_search || [];
    data
      .filter(
        (item) =>
          Number.isInteger(item.value) ||
          (item.value && item.value.length) ||
          (item.value && Object.keys(item.value).length)
      )
      .forEach((search) => {
        // We need to adapt date filters so we can send the correct data.
        switch (search.field) {
          case 'answer_date':
          case 'closed_on_date':
          case 'created_on':
          case 'due_date':
          case 'follow_up_received_date':
          case 'follow_up_sent_date':
          case 'internal_communication_date':
          case 'edited_on':
          case 'reopened_date':
            const dateTimeObject = this.helperService.getDateObject(search.value);
            result.push({
              filter_name: search.field,
              filter_values: [dateTimeObject.from, dateTimeObject.to],
              operator: search.join || Operator.And,
            });
            break;
          default:
            result.push({
              filter_name: search.field,
              filter_values: Array.isArray(search.value) ? search.value : [search.value],
              operator: search.join || Operator.And,
            });
            break;
        }
      });
    return result;
  }

  shareSearch(link: string, users: string[]): Observable<any> {
    return this.http
      .post<any>(this.URL_SHARE, { link, users }, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => resp));
  }

  postSearch(inquiryFilter: InquirySearch): Observable<InquirySearch> {
    return this.http
      .post<any>(this.URL_SEARCH, inquiryFilter, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => InquirySearch.fromRest(resp)));
  }

  updateSearch(inquiryFilter: InquirySearch): Observable<InquirySearch> {
    return this.http
      .put<any>(this.URL_SEARCH + '/' + inquiryFilter.id, inquiryFilter, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => InquirySearch.fromRest(resp)));
  }

  deleteAllSearches(): Observable<any> {
    return this.http
      .delete<any>(this.URL_SEARCH, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => resp));
  }

  deleteSearch(inquiryFilter: InquirySearch): Observable<InquirySearch> {
    return this.http
      .delete<any>(this.URL_SEARCH + '/' + inquiryFilter.id)
      .pipe(map((resp) => resp));
  }

  exportInquiry(inquiryFilter: InquirySearch): Observable<Blob> {
    const body = this.getExportBody(inquiryFilter);
    return this.http.post<Blob>(this.URL_EXPORT, body, { responseType: 'blob' as 'json' });
  }

  exportInquiryID(inquiryFilter: InquirySearch): Observable<Blob> {
    const body = this.getExportBody(inquiryFilter);
    return this.http.post<any>(this.URL_EXPORT + '/' + inquiryFilter.id, body, {
      responseType: 'blob' as 'json',
    });
  }

  private getExportBody(inquiryFilter: InquirySearch): ExportModel {
    const result: ExportModel = {
      export_columns: Object.keys(
        inquiryFilter.exportedColumns || (inquiryFilter as any).exported_columns || {}
      ),
      filters: this.getSearchBody(inquiryFilter),
    };

    return result;
  }
}
