import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError, timer } from 'rxjs';
import { catchError, finalize, mergeMap, retryWhen } from 'rxjs/operators';
import { LoadingService } from 'app/loading.service';
import { StorageService } from 'app/common/storage.service';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { environment } from 'app/../environments/environment';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HttpAuthorizationInterceptor implements HttpInterceptor {
  private isNewVersionHandled = false;

  constructor(
    private storage: StorageService,
    private dialogService: MatDialog,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', `JWT ${this.storage.getToken()}`)
      .set('X-Version', environment.version);
    const transformed = req.clone({ headers: headers, withCredentials: true });
    return next.handle(transformed).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 412) {
          if (!this.isNewVersionHandled) {
            this.handleNewVersion(err);
          }

          return throwError(
            () =>
              new HttpErrorResponse({
                ...err,
                headers: err.headers.set('skipRollbar', 'true'),
              })
          );
        }
        if (
          err instanceof HttpErrorResponse &&
          err.status === 403 &&
          (err.error.detail === 'Missing Inquiry Permissions' || err.error instanceof Blob)
        ) {
          this.process403Error();
          return EMPTY;
        }
        return throwError(() => err);
      })
    );
  }

  private handleNewVersion(errorResponse: HttpErrorResponse): void {
    this.isNewVersionHandled = true;
    if (errorResponse?.error?.displayNotice) {
      this.displayNewVersionNotice();
    } else {
      window.location.reload();
    }
  }

  private process403Error(): void {
    this.dialogService.closeAll();
    this.router.navigate(['access-denied']);
  }

  private displayNewVersionNotice(): void {
    new GenericDialogComponent.Builder(this.dialogService, { disableClose: true })
      .title('VERSION_DIALOG.TITLE')
      .message('VERSION_DIALOG.MESSAGE')
      .saveLabel('VERSION_DIALOG.SAVE_BUTTON')
      .onSave(() => window.location.reload());
  }
}

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  constructor(private _loading: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('X-NO-LOADING')) {
      return next.handle(req);
    }
    this._loading.enter();
    return next.handle(req).pipe(
      finalize(() => {
        this._loading.exit();
      }),
      catchError((err) => throwError(() => err))
    );
  }
}

const STATUS_CODES_FOR_RETRY = [0, 504];

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error, count) => {
            if (count >= 1 || !STATUS_CODES_FOR_RETRY.includes(error.status)) {
              return throwError(() => error);
            }
            return timer(1000);
          })
        );
      }),

      catchError((error: HttpErrorResponse) => {
        if (error?.status === 401) {
          this.authService.logoutAndRedirect(
            error?.error?.detail === this.authService.USER_ACCOUNT_IS_DISABLED
          );
          return EMPTY;
        }
        return throwError(() => error);
      })
    );
  }
}
